import React, { FunctionComponent } from 'react';
import { UserExercise } from '../../model/UserExercise';
import UserExerciseList from './UserExerciseList';

interface UserExerciseViewProps {
    userExercises: UserExercise | undefined,
    isPacient: boolean
}

const UserExerciseView: FunctionComponent<UserExerciseViewProps> = (
    {
        userExercises,
        isPacient
    }
) => {

    return <UserExerciseList userExercises={userExercises} isPacient={isPacient}/>

}

export default UserExerciseView;