import React from 'react';
import UrlsCustom from '../../ws/UrlsCustom';
import { getImportInfo } from './UserAccessImportUtils';

const UserAccessImportButton = () => {

    const importUser = () => {
        fetch(UrlsCustom.IMPORT_USERS, getImportInfo())
    }

    return (
        <button
            className="btn btn-dark mt-4 pull-right"
            onClick={() => { importUser() }}
        >
            <i className='fa fa-broom d-inline-block d-sm-none' />
            <span className="d-none d-sm-inline-block">Importar usuarios</span>
        </button>
    )
}

export default UserAccessImportButton