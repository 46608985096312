import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {UserExercise} from '../../model/UserExercise';
import '../../res/scss/UserExercise.scss'
import TaskGetSummaryPdf from "../../ws/TaskGetSummaryPdf";

interface UserExerciseListProps {
    userExercises: UserExercise | undefined,
    isPacient: boolean
}

const UserExerciseList: FunctionComponent<UserExerciseListProps> = (
    {
        userExercises,
        isPacient
    }
) => {
    if (!userExercises || Object.keys(userExercises).length < 1) {
        if (isPacient) {
            return  <div className="no-treatment"><p>Este paciente aún no tiene asignado ningún tratamiento</p></div>
        }
        return null
    }

    const renderCompletion = (status: string) => {
        if (status === 'completed') {
            return <i className="fa fa-check" style={{ color: "green", marginRight: "0.5em" }}></i>
        }
        if (status === 'current_day') {
            let completions = 0
            userExercises.exercises.map((exercise) => {
                if (exercise.exercisesCompleted === exercise.frequency) {
                    completions++;
                }
            })
            const completed = completions === userExercises.exercises.length

            return <i className={`${ completed ? "fa fa-check" : "fa fa-circle"}`} style={{ color: `${completed ? 'green' : 'yellow'}`, marginRight: "0.5em" }}></i>
        }
        return <i className="fa fa-times" style={{ color: "red", marginRight: "0.5em" }}></i>
    }

    const renderDaysCompletions = () => {
        return (
            <div className="completion">
                L: {renderCompletion(userExercises.L.status)}
                M: {renderCompletion(userExercises.M.status)}
                X: {renderCompletion(userExercises.X.status)}
                J: {renderCompletion(userExercises.J.status)}
                V: {renderCompletion(userExercises.V.status)}
                S: {renderCompletion(userExercises.S.status)}
                D: {renderCompletion(userExercises.D.status)}
            </div>
        )
    }

    const requestSummaryPdf = () => {
        new TaskGetSummaryPdf().get().then(
            (data) => {
                window.open(data.data.file);
        });
    }

    const renderExercises = userExercises.exercises.map(exercise => {

        const renderSteps = exercise.exerciceStep.map(step => {
            return (
                <div key={step.oid}>
                    <div className="card">
                        <div className="card-body">
                            {step.name}
                            <Link to={`/exercicestep/view/${exercise.oid}`}>
                                <i className="fa fa-eye" style={{ color: "rgb(78, 108, 115)", marginLeft: "0.5em" }}></i>
                            </Link>
                            <br />
                            Vídeo: {step.video}
                            <br />
                            Duración (s): {step.duration}
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <div className="exercise" key={exercise.oid}>
                <div className="card">
                    <div className="card-body">
                        <div className="exercise-data">
                            <p>
                                {exercise.name}: <p style={{display: 'inline'}} dangerouslySetInnerHTML={{__html: exercise.privateDescription}}></p>
                                <Link to={`/exercice/view/${exercise.oid}`}>
                                    <i className="fa fa-eye" style={{ color: "rgb(78, 108, 115)", marginLeft: "0.5em" }}></i>
                                </Link>
                                <br />
                                Duración (s): {exercise.duration}
                                <br />
                                Frecuencia: {exercise.frequency}
                                <br />
                                Repeticiones: {exercise.repetitions}
                                <br />
                                Pasos del ejercicio:
                                <br />
                                {renderSteps}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="card">
            <div className="card-body">
                <div style={{textAlign: "center"}}>
                    <button className="btn btn-light button-back" onClick={() => requestSummaryPdf()}>
                        <i className="fa fa-file-pdf" style={{ marginLeft: "0.5em", marginRight: "0.5em" }}></i>Descargar tratamiento
                    </button>
                </div>
                <div className="treatment">
                    <div>
                        <b>Ejercicios</b>:
                    </div>
                    <div className="treatment-exercise">
                        {renderDaysCompletions()}
                        <br />
                        {renderExercises}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserExerciseList

