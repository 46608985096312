export const getRequestInfo = () => {
    return {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getToken() }
    }
}

const getToken = () => document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");

export const isPacient = (userRoles: any) => {
    let isPacient = false
    Object.keys(userRoles).forEach(key => {
        if (userRoles[key].description === 'PATIENT') {
            isPacient = true
        }
    });
    return isPacient
}