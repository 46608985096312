import React, { FunctionComponent } from 'react';
import UserExerciseDataContainer from './UserExerciseDataContainer';

interface UserExerciseScreenProps {
    email: string,
    isPacient: boolean
}

const UserExerciseScreen: FunctionComponent<UserExerciseScreenProps> = (
    {
        email,
        isPacient
    }
) => {
        return <UserExerciseDataContainer email={email} isPacient={isPacient}/>;
  }

  export default UserExerciseScreen