import UrlsCustom from "./UrlsCustom";

export default class TaskGetSummaryPdf {

    private getInfo() {
        return {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
        }
    }

    public get() {
        return fetch(UrlsCustom.USER_TREATMENT_PDF.replace(':id', window.location.href.substring(window.location.href.lastIndexOf('/') + 1)), this.getInfo())
            .then(response => response.json())
            .then(data => {
                return data
            })
    }
}
const getToken = () => document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
