const BASE_URL = process.env.REACT_APP_URL + navigator.language.slice(0,2) + '/api/';
const BASE_URL_PRIVATE = BASE_URL + 'private/';
const BASE_URL_PUBLIC = BASE_URL + 'public/';

export default class {

    public static TYPOLOGY = `${BASE_URL_PRIVATE}typology/:id/view/extended`;
    public static TREATMENT = `${BASE_URL_PRIVATE}treatment/:id/view/extended`;
    public static EXERCISE = `${BASE_URL_PRIVATE}exercice/:id/view/extended`;

    public static USER_EXERCISES = `${BASE_URL_PRIVATE}exercice/week?email=:email`;

    public static USER_TREATMENT_PDF = `${BASE_URL_PRIVATE}user/:id/treatment/summary`;

    public static IMPORT_USERS = `${BASE_URL_PRIVATE}useraccess/import_users`;

}