import React from 'react';
import { FormRenderData, Module } from '@cuatroochenta/co-admin-react-library';

import '@cuatroochenta/co-admin-react-library/lib/index.css';
import UserExerciseScreen from './modules/userexcercises/UserExercisesScreen';
import CustomAction from './CustomAction';
import UserAccessImportButton from './modules/useraccess/UserAccessImportButton';

import './res/scss/Login.scss'
import { isPacient } from './modules/userexcercises/UserExerciseUtils';

interface FiltersType {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        if (entity === 'user' && type === 'view') {
            return (
                <UserExerciseScreen email={data.fields.email} isPacient={isPacient(data.relationsValues.userRoles)}/>
            )
        }
        return null
    };

    private customButtonsList = (entity: string, filters: FiltersType, orderby: string, order: string): React.ReactNode => {
        if (entity === 'user') {
            return (
                <UserAccessImportButton/>
            )
        }
        return null;
    };

    public render() {
        return (
            <Module
                actions={[
                    { name: '', element:  <CustomAction/>}
                ]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
            />
        );
    }

}

export default App;
