import React, { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { UserExercise } from '../../model/UserExercise';
import UrlsCustom from '../../ws/UrlsCustom';
import { getRequestInfo } from './UserExerciseUtils';
import UserExerciseView from './UserExerciseView';

interface UserExerciseDataContainerProps {
    email: string,
    isPacient: boolean
}

const UserExerciseDataContainer: FunctionComponent<UserExerciseDataContainerProps> = (
    {
        email,
        isPacient
    }
) => {

    const [ userExercises, setUserExcercises ] = useState<UserExercise>()

    useEffect(() => {
        fetch(UrlsCustom.USER_EXERCISES.replace(':email', email), getRequestInfo())
        .then(response => response.json())
        .then(data => {
            setUserExcercises(data.data)
        })
    }, [])

    return <UserExerciseView userExercises={userExercises} isPacient={isPacient}/>
}

export default UserExerciseDataContainer;